import { FC, useState, useEffect } from 'react';
import BtnClose from '../../components/BtnClose';
import styled from '@emotion/styled';
import * as API from '../../api';
import Select from 'react-select';
import ReportFilter from './ReportFilter';
import { useDistributors, useIndustries } from '../../modules/auth';

const Style = styled.div`

  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }

  .mailling-item {
    margin-right: 30px;
    
    label {
        margin-right: 10px;
    }
}

.radio-item {
    margin-right: 30px;
    
    label {
        margin-right: 5px;
    }

    .opacity-test {
    
        background-color: red;
    }
`;

type Props = {
    onChange: Function,
    onClose: Function,
    data: any,
    type: string
}

const LojistasFilterModal: FC<Props> = (props) => {
    const distributors = useDistributors();
    const industries = useIndustries();

    const [loading, setLoading] = useState(false);

    const [selectedDistributors, setSelectedDistributors] = useState<any[]>([]);
    const [selectedIndustries, setSelectedIndustries] = useState<any[]>([]);

    const [nomeCupom, setNomeCupom] = useState<string>('');
    const [idRegraPromocao, setIDRegraPromocao] = useState<string>('');
    const [cupomUtilizado, setCupomUtilizado] = useState<string>('');

    const [filter, setFilter] = useState<any>(null);
    
    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (filter || props.data) {
            const data = filter?.data || props.data;
            if (data.nomeCupom) setNomeCupom(data.nomeCupom);
            if (data.regraPromocao) setIDRegraPromocao(data.regraPromocao);
            if (data.cupomUtilizado) setCupomUtilizado(data.cupomUtilizado);
        }
    }, [props.data, filter]);

    function getData() {
        return {
            nomeCupom,
            regraPromocao:idRegraPromocao,
            cupomUtilizado
        }
    }

    return (
        <Style>
            <div className='modal-container'>
                
                <div className='px-7 py-5 d-flex justify-content-between'>
                    <div className='pt-2 fs-3 text-dark fw-bolder'>Filtros para o relatório</div>
                    <BtnClose onClick={() => props.onClose()} />    
                </div>
                <ReportFilter name={props.type} filter={getData()} setFilter={setFilter}></ReportFilter>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5'>
                    <div className='mb-4 d-flex flex-wrap'>
                        <div className=' justify-content-between modal-container'>

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Nome do Cupom:</label>
                                <input className="form-input form-control" type='text' disabled={loading} defaultValue={nomeCupom} onChange={(e) => {
                                    setNomeCupom(e.target.value);
                                }}></input>
                            </div>
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>ID Regra Promoção:</label>
                                <input className="form-input form-control" type='text' disabled={loading} defaultValue={idRegraPromocao} onChange={(e) => {
                                    setIDRegraPromocao(e.target.value);
                                }}></input>
                            </div>

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Cupom Utilizado</label>
                                <div className='form-control d-flex'>
                                    <div className='radio-item'>
                                        <label>Todos</label>
                                        <input className='form-check-input' type='radio' name='cupomutilizado' value='' checked={ cupomUtilizado === '' } onChange={({ target: { value } }) => { setCupomUtilizado(value) }}/>
                                    </div> 
                                    <div className='radio-item'>
                                        <label>Sim</label>
                                        <input className='form-check-input' type='radio' name='cupomutilizado' value='1' checked={ cupomUtilizado === '1' } onChange={({ target: { value } }) => { setCupomUtilizado(value) }}/>
                                    </div>                                
                                    <div className='radio-item'>
                                        <label>Não</label>
                                        <input className='form-check-input' type='radio' name='cupomutilizado' value='0' checked={ cupomUtilizado === '0' } onChange={({ target: { value } }) => { setCupomUtilizado(value) }}/>
                                    </div>
                                </div>                                
                            </div>
                            
                            <button onClick={() => {
                                setLoading(true);
                                props.onChange(getData());
                            }} className='btn btn-primary filter-btn'>{!loading && 'Filtrar'} {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Aguarde...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
}

export default LojistasFilterModal;