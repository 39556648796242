import React, { FC, useEffect, useState } from 'react';
import {PageLink, PageTitle} from './../../../_metronic/layout/core'
import styled from '@emotion/styled';
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { messages } from '../utils/ErrorsText';
import { isSuperAdmin } from '../../modules/auth/core/Auth';
import { getUserData } from '../../modules/auth/core/Auth';
import IconAttachment from './../../components/IconAttachment';
import BtnView from './../../components/BtnStatusOpen';
import BtnTrash from '../../components/BtnTrash';
import BtnEdit from '../../components/BtnEdit';

const StyledModal = styled.div`
    .card-header {
        align-items: center;
        background-color: #f8f9fa;
        border-bottom: 1px solid #dee2e6;
    }

    .card-title {
        color: #495057;
    }

    .nps-view {
        width: 80%;
        zoom: 0.75;
        margin: auto;
        border-color: #DDDDDD;
    }

    .nps {
        margin-top: 40px;
    }

    .nps-loading {
        margin-top: 60px;
        margin-bottom: 40px;
        font-size: 14px;
    }

    .nps-box-parent {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .nps-buttons-box {
        display: flex;
    }

    .nps-box {
        width: 100%;
        display: flex;
        flex-direction: column; // Altere para 'column'
        align-items: center;
        margin: 10px;
        padding: 10px;
        border-color: #CCCCCC;
        border-style: dashed;

        div {
            width: 100%;
        }

        .nps-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
            width: 100%;
            text-align: center;
        }

        .nps-images {
            display: flex;
            flex-direction: row;
            justify-content: center;

            img {
                max-width: 50px; // Adicione esta linha
                margin: 2px;
                cursor: pointer;
                transition: opacity 0.3s ease;
            }
        }
    }

    button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0056b3;
        }
    }
`;

const NPSEditor = () => {
    const [loading, setLoading] = useState(false);
    const [nps, setNPS] = useState<any>([]);
    const [npsIsLoaded, setNPSIsLoaded] = useState<boolean>(false);
    const [ratings, setRatings] = useState<{ [key: string]: number }>({});

    const ratingNames = ['Insatisfeito', 'Triste', 'Neutro', 'Bom', 'Ótimo']

    function getData() {
        const data = [];
        for (const key in ratings) {
            const item = getNPSById(key);
            data.push({id_nps:key, title:item.title, value:ratings[key]});
        }
        return data;
    }

    function getNPSById(id:string) {
        for (let a = 0; a < nps.length; a++) {
            if (nps[a].id_nps == id) return nps[a];
        }
        return null;
    }

    async function onSubmit() {
        const data = getData();
        setLoading(true);        
        try {
            await API.saveNPS(data);
            setLoading(false)
        } catch (e) {
            console.log(e);
            setLoading(false)
        }
    }

    function handleRatingChange(rating: any, group: string) {
        setRatings(prevRatings => ({ ...prevRatings, [group]: rating }));
    }

    const getNPS = async() => {
        const response = await API.getNPSView();
        if (response.data) setNPS(response.data);
        setNPSIsLoaded(true);
    }

    const editNPS = async(nps:any) => {
        const response = await API.editNPS({ title:nps.title, guid:nps.guid });
        setNPSIsLoaded(false);
        await getNPS();
    }

    const removeNPS = async(nps:any) => {
        const response = await API.deleteNPS({ guid:nps.guid });
        setNPSIsLoaded(false);
        await getNPS();
    }

    useEffect(() => {
        getNPS();
    }, []);

    const allRatingsSelected = nps.length === Object.keys(ratings).length;

    return (
        <StyledModal className="card mb-5">
            <PageTitle>Gerenciador de NPS</PageTitle>
            <div className='card-header border-0'>
                <div className='card-title m-0'>
                    
                </div>
            </div>
            <div className='nps-view'>
            <div className='card mb-5 mb-xl-10 p-10'>        
                <div className='card-body text-center pb-0'>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configure de acordo com sua necessidade o NPS</p>
                    { npsIsLoaded ? <><div className='nps'>
                    { nps.map((item:any) => (
                        <div className='nps-box-parent'>
                            <div key={item.guid} className='nps-box'>
                                <input type="text" className='nps-title' defaultValue={item.title} onChange={(evt) => {
                                    item.title = evt.target.value;
                                }}></input>
                                { item.type === 'emoticon' ? 
                                <div className='nps-images'>
                                {[1, 2, 3, 4, 5].map(rating => (
                                    <img 
                                        src={toAbsoluteUrl(`/media/rating/rating_${rating}.png`)}
                                        alt={ratingNames[rating - 1]}
                                        key={'img_' + Math.random()}
                                        title={ratingNames[rating - 1]}
                                        style={{ opacity: ratings[item.id_nps] === rating ? 1 : 0.4 }} 
                                        onClick={() => handleRatingChange(rating, item.id_nps)}
                                    />
                                ))}
                                </div>
                                : item.type === 'number' ?
                                <div>
                                    <div key={item.guid + "_" + Math.random()} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                        {[1, 2, 3, 4, 5].map(number => (
                                        <button 
                                            key={'button_' + Math.random()}
                                            style={{ 
                                                opacity: ratings[item.id_nps] === number ? 1 : 0.4,
                                                marginRight: '10px'
                                            }} 
                                            onClick={() => handleRatingChange(number, item.id_nps)}
                                        >
                                            {number}
                                        </button>
                                    ))}
                                    </div>
                                </div>
                                : item.type === 'text' ?
                                <div key={item.guid + "_" + Math.random()} style={{ marginTop: '10px' }}>
                                    <textarea 
                                        style={{ 
                                            width: '100%', 
                                            height: '100px', 
                                            padding: '10px', 
                                            border: '1px solid #ccc', 
                                            borderRadius: '4px',
                                            resize: 'none'
                                        }} 
                                        onChange={(e) => handleRatingChange(e.target.value, item.id_nps)} 
                                    />
                                </div>
                                : <></> }
                            </div>
                            <div className='nps-buttons-box'>
                                <button onClick={() => { editNPS(item)}} className='btn btn-icon btn-bg-dark btn-active-color-light btn-sm me-1'>
                                    <BtnEdit/>
                                </button>
                                <button onClick={() => {removeNPS(item)}} className='btn btn-icon btn-bg-danger btn-active-color-light btn-sm'>
                                    <BtnTrash/>
                                </button>
                            </div>
                        </div>))}
                    </div>
                </>: <div className='nps-loading'>Carregando</div> }
            
            </div>
            </div>
        </div>
    </StyledModal>
)
}

export default NPSEditor;