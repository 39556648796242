import axios from 'axios';
import { getUserData } from './modules/auth'
import { isAdmin } from './modules/auth/core/Auth';
import { isSuperAdmin } from './modules/auth/core/Auth';

axios.defaults.baseURL = process.env.REACT_APP_API_URL; 
axios.defaults.timeout = 1200000;

axios.interceptors.response.use(function (response) {
    try {
        if (!response.data.success) {
            if (response.data.error === 'INVALID_TOKEN') {
                localStorage.clear();
                window.location.href = window.location.href;
            } else if (response.data.error === 'AUTHENTICATION_FAIL' && window.location.href.indexOf('auth') == -1){
                localStorage.clear();
                window.location.href = window.location.href;
            }
        } 
    } catch (e) { }
    return response;
  }, function (error) {
    return Promise.reject(error);
});

export var companys = [];

export async function verify() {
    const res = await axios.post("/auth/verify");
    return res.data;
}

export async function login(email:string, password:string) {
    const res = await axios.post("/user/auth", {email, password});
    return res.data;
}

export async function forgotPassword(email: string) {
    const res = await axios.post("/user/forgot-password", {email});
    return res.data;
}

export async function resetPassword(token: string, userGuid: string, email: string, password: string) {
    const res = await axios.post("/user/reset-password", {token, userGuid, email, password });
    return res.data;
}

export async function createPassword(token: string, userGuid: string, email: string, password: string) {
    const res = await axios.post("/user/create-password", {token, userGuid, email, password });
    return res.data;
}

export async function verifyEmail(token: string, userGuid: string, email: string) {
    const res = await axios.post("/user/verify-email", {token, userGuid, email });
    return res.data;
}

export async function updateUserData() {
    const res:any = await axios.get("/user");
    try {
        const userData:any = localStorage.getItem('auth');
        for (var key in res.data.data) {
            userData[key] = res.data.data[key];
        }
        if (res.data.success) localStorage.setItem('auth', JSON.stringify(userData));    
    } catch (e) {
        console.log(e);
    }
    return res.data;
}

export async function listUsers() {
    let params = '';
    if (isAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/user/list${params}`);
    return res.data;
}

export async function getMenu() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/menu${params}`);
    return res.data;
}

export async function createCategory(name: string) {
    let companyGuid = getUserData().company_guid;
    const currentCompany = localStorage.getItem("current_company");
    if (isSuperAdmin() && currentCompany) companyGuid = currentCompany;
    const res = await axios.post("/category", {
        name,
        company_guid: companyGuid
    });
    return res.data;
}

export async function deleteCategory(categoryGuid: string) {
    const res = await axios.delete("/category", { data: { category_guid: categoryGuid } });
    return res.data;
}

/**
 * Products
 */
 export async function createProduct(name: string, categoryGuid: string) {
    const res = await axios.post("/product", {
        name,
        category_guid: categoryGuid
    });
    return res.data;
}

export async function deleteProduct(productGuid: string) {
    const res = await axios.delete("/product", { data: { product_guid: productGuid } });
    return res.data;
}

/**
 * Sub Products
 */
 export async function createSubProduct(name: string, productGuid: string) {
    const res = await axios.post("/subproduct", {
        name,
        product_guid: productGuid
    });
    return res.data;
}

export async function deleteSubProduct(subProductGuid: string) {
    const res = await axios.delete("/subproduct", { data: { subproduct_guid: subProductGuid } });
    return res.data;
}

/**
 * Charts 
 */
export async function createChart(type:string, query:string, title:string, dashboardId:string) {
    const res = await axios.post("/chart", {
        query,
        chart:type,
        title,
        dashboard_guid:dashboardId
    });
    return res.data;
}

export async function createPowerBI(datasets:string, tenant_id:string, group_id:string, report_id:string, dashboardId:string) {
    const res = await axios.post("/powerbi", {
        tenant_id,
        datasets,
        group_id,
        report_id,
        dashboard_guid:dashboardId
    });
    return res.data;
}

export async function updatePowerBI(chartId:string, data:any) {
    const res = await axios.put("/powerbi", {
        chart_guid:chartId,
        tenant_id: data.tenant_id,
        datasets: data.datasets,
        group_id:data.group_id,
        report_id:data.report_id
    });
    return res.data;
}

export async function loadTenants() {
    const res = await axios.get("/powerbi/tenants");
    return res.data;
}

export async function loadGroups(tenant_id:string) {
    const res = await axios.post("/powerbi/groups", {
        tenant_id
    });
    return res.data;
}

export async function loadReports(tenant_id:string, group_id:string) {
    const res = await axios.post("/powerbi/reports", {
        tenant_id,
        group_id
    });
    return res.data;
}

export async function loadGeneralMenu() {
    const res = await axios.get("/menu/all");
    return res.data;
}

export async function loadMenuPermissions() {
    const res = await axios.get("/menu/permissions");
    return res.data;
}

export async function getAllCategorys() {
    const res = await axios.get("/category/all");
    return res.data;
}

export async function getCategoryPermissions(categoryId:string) {
    const res = await axios.get(`/menu/permissions/${categoryId}`);
    return res.data;
}

export async function loadUsersPermissions() {
    const currentCompany = localStorage.getItem("current_company");
    const params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    const res = await axios.get(`/user/list-permissions` + params);
    return res.data;
}

export async function updateChart(chartId:string, data:any) {
    const res = await axios.put("/chart", {
        chart_guid:chartId,
        query: data.query,
        chart: data.chart,
        title: data.title
    });
    return res.data;
}

export async function listCompanys() {
    const res = await axios.get("/company/list");
    return res.data;
}

export async function removeChart(chartId:string) {
    const res = await axios.delete("/chart", { data: { chart_guid: chartId } } );
    return res.data;
}

export async function removePowerBI(powerbi_guid:string) {
    const res = await axios.delete("/powerbi", { data: { powerbi_guid }} );
    return res.data;
}

export async function loadDashboard(dashboardId:string) {
    const res = await axios.get(`/dashboard?dashboard_guid=${dashboardId}`);
    return res.data;
}

export async function loadHome() {
    const res = await axios.get(`/home`);
    return res.data;
}

export async function updateDashboardLayout(dashboard_guid:string, data:any) {
    if (!isSuperAdmin()) return;
    const res = await axios.put(`/dashboard`, { dashboard_guid, data: JSON.stringify(data)});
    return res.data;
}

export async function updateHomeLayout(data:any) {
    const res = await axios.put(`/home`, { data: JSON.stringify(data)});    
    return res.data;
}


export async function deleteUser(user_guid:string) {
    const res = await axios.delete(`/user`, { data: { user_guid }});    
    return res.data;
}

export async function deleteCompany(company_guid:string) {
    const res = await axios.delete(`/company`, { data: { company_guid }});    
    return res.data;
}

export async function createCompany(data:any) {
    const res = await axios.post(`/company`, data);
    return res.data;
}

export async function updateCompany(data:any) {
    const res = await axios.put(`/company`, data);
    return res.data;
}

export async function updateUser(data:any) {
    const res = await axios.put(`/user`, { 
        user_guid:data.user_guid,
        name:data.name,
        email:data.email,
        id_upload:data.id_upload,
        password:data.password,
        company_guid:data.company_guid,
        is_admin:data.is_admin,
        is_actived:data.is_actived,
        is_mailling:data.is_mailling,
        is_viewer:data.is_viewer
    });    
    return res.data;
}

export async function changePassword(data:any) {
    const res = await axios.put(`/user/password`, { 
        user_guid:data.user_guid,
        password:data.password
    });    
    return res.data;
}

export async function createUser(data:any) {
    const res = await axios.post(`/user`, { 
        name:data.name,
        email:data.email,
        password:data.password,
        id_upload:data.id_upload,
        company_guid:data.company_guid,
        is_admin:data.is_admin,
        is_actived:data.is_actived,
        is_mailling:data.is_mailling,
        is_viewer:data.is_viewer
    });    
    return res.data;
}

export async function addPowerBIToHome(powerbi_guid:string, dashboard_guid:string) {
    const res = await axios.post(`/powerbi/home`, { 
        powerbi_guid,
        dashboard_guid
    });    
    return res.data;
}

export async function removePowerBIFromHome(powerbi_guid:string, dashboard_guid:string) {
    const res = await axios.delete(`/powerbi/home`, { data: { powerbi_guid, dashboard_guid }});    
    return res.data;
}

export async function addChartToHome(chart_guid:string, dashboard_guid:string) {
    const res = await axios.post(`/chart/home`, { 
        chart_guid,
        dashboard_guid
    });    
    return res.data;
}

export async function removeChartFromHome(chart_guid:string, dashboard_guid:string) {
    const res = await axios.delete(`/chart/home`, { data: { chart_guid, dashboard_guid }});    
    return res.data;
}

export async function getCompanyTypes() {
    const res = await axios.get(`/company/types`);    
    return res.data;
}

export async function editProduct(product_guid:string, name:string) {
    const res = await axios.put("/product", {
        product_guid,
        name
    });
    return res.data;
}

export async function editCategory(category_guid:string, name:string) {
    const res = await axios.put("/category", {
        category_guid,
        name
    });
    return res.data;
}

export async function editSubProduct(subproduct_guid:string, name:string) {
    const res = await axios.put("/subproduct", {
        subproduct_guid,
        name
    });
    return res.data;
}

export async function addProductCompanyPermission(data:any) {
    const res = await axios.post(`/product/company`, data);    
    return res.data;
}

export async function removeProductCompanyPermission(data:any) {
    const res = await axios.delete(`/product/company`, {data:data});
    return res.data;
}

export async function addSubProductCompanyPermission(data:any) {
    const res = await axios.post(`/subproduct/company`, data);    
    return res.data;
}

export async function removeSubProductCompanyPermission(data:any) {
    const res = await axios.delete(`/subproduct/company`, {data:data});
    return res.data;
}

export async function addProductUserPermission(data:any) {
    const res = await axios.post(`/product/user`, data);    
    return res.data;
}

export async function removeProductUserPermission(data:any) {
    const res = await axios.delete(`/product/user`, {data:data});
    return res.data;
}

export async function addSubProductUserPermission(data:any) {
    const res = await axios.post(`/subproduct/user`, data);    
    return res.data;
}

export async function removeSubProductUserPermission(data:any) {
    const res = await axios.delete(`/subproduct/user`, {data:data});
    return res.data;
}



export async function removeCategory(category_guid:string) {
    const res = await axios.delete(`/category`, {data:{category_guid}});
    return res.data;
}

export async function removeProduct(product_guid:string) {
    const res = await axios.delete(`/product`, {data:{product_guid}});
    return res.data;
}

export async function removeSubProduct(subproduct_guid:string) {
    const res = await axios.delete(`/subproduct`, {data:{subproduct_guid}});
    return res.data;
}

export async function uploadImage(data:any) {
    const res = await axios.post(`/upload/image`, data, { headers: { 
        "Content-Type":"application/json;charset=UTF-8",
        "Accept": "application/json, text/plain, */*"
    }});
    return res.data;
}



export async function getSupportList() {
    let params = '';
    params += `?&search=&page=1&per_page=1000`;
    const res = await axios.get(`/support/list${params}`);
    return res.data;
}

export async function listSupportSubject() {
    const res = await axios.get(`/support/list-subject`);
    return res.data;
}

export async function listSupportStatus() {
    const res = await axios.get(`/support/list-status`);
    return res.data;
}

export async function getAllSupportList() {
    let params = '';
    if (isAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    params += `&search=&page=1&per_page=1000`;
    const res = await axios.get(`/support/list/all${params}`);
    return res.data;
}

export async function addSupportTicket(data:any) {
    const res = await axios.post(`/support/add`, data);
    return res.data;
}

export async function addSupportComment(data:any) {
    const res = await axios.post(`/support/add-support-comment`, data);
    return res.data;
}

export async function updateSupportStatus(data:any) {
    const res = await axios.put(`/support/update-status`, data);
    return res.data;
}

export async function getTicketNumber(ticket_guid:string) {
    const res = await axios.get(`/support/ticket-number?ticket_guid=${ticket_guid}`);
    return res.data;
}

export async function getTicketDetails(ticket_guid:string) {
    const res = await axios.get(`/support/ticket-details?ticket_guid=${ticket_guid}`);
    return res.data;
}

export async function checkForNewNotifications() {
    const res = await axios.get(`/notification`);
    return res.data;
}

export async function markAllNotificationsAsRead() {
    const res = await axios.post(`/notification/readall`);
    return res.data;
}

export async function uploadFile(file:any) {
    const formData = new FormData();
    formData.append('user_guid', getUserData().guid);
    formData.append('file', file);
    const res = await axios.post(`/upload/file`, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
    return res.data;
}

export async function selectAllUsersIntoSubproduct(id_subproduct:any) {
    let companyGuid = getUserData().company_guid;
    const currentCompany = localStorage.getItem("current_company");
    if (isSuperAdmin() && currentCompany) companyGuid = currentCompany;

    const res = await axios.post(`/subproduct/user/all`, { company_guid:companyGuid, id_subproduct });
    return res.data;
}

export async function unselectAllUsersIntoSubproduct(id_subproduct:any) {
    let companyGuid = getUserData().company_guid;
    const currentCompany = localStorage.getItem("current_company");
    if (isSuperAdmin() && currentCompany) companyGuid = currentCompany;

    const res = await axios.delete(`/subproduct/user/all`, { data: {company_guid:companyGuid, id_subproduct }});
    return res.data;
}

export async function selectAllUsersIntoProduct(id_product:any) {
    let companyGuid = getUserData().company_guid;
    const currentCompany = localStorage.getItem("current_company");
    if (isSuperAdmin() && currentCompany) companyGuid = currentCompany;

    const res = await axios.post(`/product/user/all`, { company_guid:companyGuid, id_product });
    return res.data;
}

export async function unselectAllUsersIntoProduct(id_product:any) {
    let companyGuid = getUserData().company_guid;
    const currentCompany = localStorage.getItem("current_company");
    if (isSuperAdmin() && currentCompany) companyGuid = currentCompany;

    const res = await axios.delete(`/product/user/all`, { data:{company_guid:companyGuid, id_product }});
    return res.data;
}


export async function selectAllCompanysIntoProduct(id_product:any) {
    const res = await axios.post(`/product/company/all`, { id_product });
    return res.data;
}

export async function unselectAllCompanysIntoProduct(id_product:any) {
    const res = await axios.delete(`/product/company/all`, { data:{ id_product }});
    return res.data;
}

export async function selectAllCompanysIntoSubproduct(id_subproduct:any) {
    const res = await axios.post(`/subproduct/company/all`, { id_subproduct });
    return res.data;
}

export async function unselectAllCompanysIntoSubproduct(id_subproduct:any) {
    const res = await axios.delete(`/subproduct/company/all`, { data:{ id_subproduct }});
    return res.data;
}

export async function addManualIntoPowerBI(data:any) {
    const res = await axios.post(`/powerbi/manual`, data);
    return res.data;
}

/*export async function savePowerBIFilters(data:any) {
    const res = await axios.post(`/powerbi/filters`, data);
    return res.data;
}*/

export async function getPowerBIFilters() {
    const res = await axios.get(`/powerbi/filters`);
    return res.data;
}

export async function addPowerBIFilter(data:any) {
    const res = await axios.post(`/powerbi/filter`, data);
    return res.data;
}

export async function updatePowerBIFilter(data:any) {
    const res = await axios.put(`/powerbi/filter`, data);
    return res.data;
}

export async function removePowerBIFilter(data:any) {
    const res = await axios.delete(`/powerbi/filter`, { data });
    return res.data;
}

export async function getGuidelines() {
    const res = await axios.get(`/guidelines`);
    return res.data;
}

export async function acceptGuidelines() {
    const res = await axios.post(`/guidelines`);
    return res.data;
}

export async function addNewGuidelines(data:any) {
    const res = await axios.post(`/guidelines/add`, data);
    return res.data;
}

export async function getNPS() {
    const res = await axios.get(`/nps`);
    return res.data;
}

export async function getNPSView() {
    const res = await axios.get(`/nps/view`);
    return res.data;
}

export async function saveNPS(data:any) {
    const res = await axios.post(`/nps`, data);
    return res.data;
}

export async function editNPS(data:any) {
    const res = await axios.put(`/nps`, data);
    return res.data;
}

export async function deleteNPS(data:any) {
    const res = await axios.delete(`/nps`, { data });
    return res.data;
}

export async function getMonitorAlerts() {
    const res = await axios.get(`/alert/list`);
    return res.data;
}

export async function getMonitorPromos() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/monitor/promos${params}`);
    return res.data;
}

export async function addNewMonitorAlert(data:any) {
    const res = await axios.post(`/alert/new`, data);
    return res.data;
}

export async function getAlertHistory(guid:any) {
    const res = await axios.get(`/alert/history/${guid}`);
    return res.data;
}

export async function getAlert(guid:any) {
    const res = await axios.get(`/alert/${guid}`);
    return res.data;
}

export async function editAlert(guid:any, data:any) {
    const res = await axios.put(`/alert/${guid}`, data);
    return res.data;
}

export async function removeAlert(guid:any) {
    const res = await axios.delete(`/alert/${guid}`);
    return res.data;
}

export async function getUserFeatures() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/menu/user-features${params}`);
    return res.data;
}


export async function getFeatures() {
    const res = await axios.get(`/menu/features`);
    return res.data;
}

export async function getCompanyFeatures() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/features${params}`);
    return res.data;
}

export async function getAlertRegions() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/alert/regions${params}`);
    return res.data;
}

export async function getReport(params:any, type:string, exportFile:boolean = false, limit = 100) {
    const distributors = params.distributors ? params.distributors.map((item:any) => { return item.value}).join(",") : '';
    const industries = params.industries ? params.industries.map((item:any) => { return item.value}).join(",") : '';
    let regions = params.regions ? "'" + params.regions.map((item:any) => { return item.value}).join("','") + "'": '';
    let bu = params.bu ? "'" + params.bu.map((item:any) => { return item.value}).join("','") + "'": '';
    let cnpj = params?.cnpj?.indexOf(",") != -1 ? "'" + params?.cnpj?.split(',').join("','") + "'" : params.cnpj;
    if (cnpj == "''" || cnpj == "'undefined'" || !cnpj) cnpj = ""; 
    
    let cepInicial = params?.cepInicial?.indexOf(",") != -1 ? "'" + params?.cepInicial?.split(',').join("','") + "'" : params.cepInicial;
    if (cepInicial == "''" || cepInicial == "'undefined'" || !cepInicial) cepInicial = ""; 
    let cepFinal = params?.cepFinal?.indexOf(",") != -1 ? "'" + params?.cepFinal?.split(',').join("','") + "'" : params.cepFinal;
    if (cepFinal == "''" || cepFinal == "'undefined'" || !cepFinal) cepFinal = ""; 
    let segmento = params?.segmento?.indexOf(",") != -1 ? "'" + params?.segmento?.split(',').join("','") + "'" : params.segmento;
    if (segmento == "''" || segmento == "'undefined'" || !segmento) segmento = ""; 
    let UF = params?.UF?.indexOf(",") != -1 ? "'" + params?.UF?.split(',').join("','") + "'" : params.UF;
    if (UF == "''" || UF == "'undefined'" || !UF) UF = ""; 
    let municipio = params?.municipio?.indexOf(",") != -1 ? "'" + params?.municipio?.split(',').join("','") + "'" : params.municipio;
    if (municipio == "''" || municipio == "'undefined'" || !municipio) municipio = ""; 


    let ean = params?.ean?.indexOf(",") != -1 ? "'" + params?.ean?.split(',').join("','") + "'" : params.ean;
    if (ean == "''" || ean == "'undefined'" || !ean) ean = ""; 
    let sku = params?.idSKU?.indexOf(",") != -1 ? "'" + params?.idSKU?.split(',').join("','") + "'" : params.idSKU;
    if (sku == "''" || sku == "'undefined'" || "sku") sku = ""; 
    let pedido = params?.idPedido?.indexOf(",") != -1 ? "'" + params?.idPedido?.split(',').join("','") + "'" : params.idPedido;
    if (pedido == "''" || pedido == "'undefined'" || !pedido) pedido = ""; 
    const nomeCupom = params.nomeCupom ? params.nomeCupom : '';
    const cupomUtilizado = params.cupomUtilizado ? params.cupomUtilizado : '';
    const search = params.produto ? params.produto : '';
    const marca = params.marca ? params.marca : '';
    const regraPromocao = params.regraPromocao ? params.regraPromocao : '';
    const politicaPreco = params.politicaPreco ? params.politicaPreco : '';
    const isBlockList = params.isBlockList;
    const dataNFIntegracao = params.dataNFIntegracao;
    const dataFaturadoIntegracao = params.dataFaturadoIntegracao;
    const groupBySKU = params.groupBySKU;
    if (regions == "''") regions = ""; 
    if (bu == "''") bu = ""; 
    let paramsStr = `?status=${params.status}&nomeCupom=${nomeCupom}&cupomUtilizado=${cupomUtilizado}&dtstart=${params.dtstart}&dtend=${params.dtend}&cnpj=${cnpj}&idSKU=${sku}&idPedido=${pedido}&industries=${industries}&distributors=${distributors}&search=${search}&regions=${regions}&regraPromocao=${regraPromocao}&politicaPreco=${politicaPreco}&isBlockList=${isBlockList}&type=${params.type}&year=${params.year}&month=${params.month}&ean=${ean}&marca=${marca}&dataNFIntegracao=${dataNFIntegracao}&dataFaturadoIntegracao=${dataFaturadoIntegracao}&groupBySKU=${groupBySKU}&bu=${bu}&cepInicial=${cepInicial}&cepFinal=${cepFinal}&segmento=${segmento}&UF=${UF}&municipio=${municipio}&limit=${limit}`;
    
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        paramsStr += currentCompany ? `&company_guid=${currentCompany}` : `&company_guid=${getUserData().company_guid}`;
    }
    try {
        const res = await axios.get(`/reports/${exportFile ? 'export/' : ''}${type}${encodeURI(paramsStr)}`);
        return res.data;
    } catch (e) {
        console.log("GET REPORT RESPONSE", e);
    }
    return null;
}

export async function getAlertDistributors() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/alert/distributors${params}`);
    return res.data;
}

export async function addFeatureCompanyPermission(data:any) {
    const res = await axios.post(`/feature/company`, data);    
    return res.data;
}

export async function removeFeatureCompanyPermission(data:any) {
    const res = await axios.delete(`/feature/company`, {data:data});
    return res.data;
}

export async function addFeatureUserPermission(data:any) {
    const res = await axios.post(`/feature/user`, data);    
    return res.data;
}

export async function removeFeatureUserPermission(data:any) {
    const res = await axios.delete(`/feature/user`, {data:data});
    return res.data;
}



export async function getBusinessUnits() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/company/business-units${params}`);
    return res.data;
}

export async function getRegions() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/company/regions${params}`);
    return res.data;
}

export async function getDistributors() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/company/distributors${params}`);
    return res.data;
}

export async function getIndustries() {
    let params = '';
    if (isSuperAdmin()) {
        const currentCompany = localStorage.getItem("current_company");
        params = currentCompany ? `?company_guid=${currentCompany}` : `?company_guid=${getUserData().company_guid}`;
    }
    const res = await axios.get(`/company/industries${params}`);
    return res.data;
}

export async function checkJobId(jobId:any) {
    return await axios.get(`/jobs/${jobId}`);
}

export async function getReportFilters(report:string) {
    const res = await axios.get(`/reports/filters/${report}`);
    return res.data;
}

export async function addReportFilter(report:string, data:any) {
    const res = await axios.post(`/reports/filters/${report}`, data);
    return res.data;
}

export async function updateReportFilter(data:any) {
    const res = await axios.put(`/reports/filters`, data);
    return res.data;
}

export async function removeReportFilter(data:any) {
    const res = await axios.delete(`/reports/filters`, {data});
    return res.data;
}

export async function addManualIntoReport(data:any) {
    const res = await axios.post(`/reports/manual`, data);
    return res.data;
}

export async function getManualReport(type:string) {
    const res = await axios.get(`/reports/manual/${type}`);
    return res.data;
}




export async function listLogs(userId = null, typeId = null, startDate:any = null, endDate:any = null) {
    const res = await axios.get(`/logs?user=${userId}&type=${typeId}&start=${startDate}&end=${endDate}`);
    return res.data;
}

export async function listLogTypes() {
    const res = await axios.get(`/logs/types`);
    return res.data;
}


export async function dashboardHelpClick(data:any) {
    const res = await axios.post(`/dashboard/help`, data);
    return res.data;
}

export async function dashboardFullscreenClick(data:any) {
    const res = await axios.post(`/dashboard/fullscreen`, data);
    return res.data;
}




export async function generateMFA() {
    const res = await axios.get(`/mfa/generate`);
    return res.data;
}

export async function addAndValidateMFA(data:any) {
    const res = await axios.post(`/mfa/add-validate`, data);
    return res.data;
}