/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useEffect } from 'react';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { AddMenuItemModal } from '../../../partials/modals/add-menu-item/AddMenuItemModal';
import { getMenu, getCompanyFeatures } from '../../../../app/api';
import styled from '@emotion/styled';
import { isSuperAdmin } from '../../../../app/modules/auth';

const StyledMenu = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 12px;
	height: 100%;

	.btn-add-container {
		padding: 16px;
	}

	.text-center {
		color:#d8d8d8;
		text-align:center;
	}

	.menu-item-border {
		border-bottom: 1px solid #ccc!important;
	}

	p {
		color:#d8d8d8;
		text-align:center;
	}
`

export function AsideMenuMain() {
	const [menu, setMenu] = useState<any[]|undefined>(undefined);
	const [features, setFeatures] = useState<any[]|undefined>(undefined);
	const [showAddMenuItemModal, setShowAddMenuItemModal] = useState(false);
	const reports = [
		'pedidos-report',
		'promocoes-report',
		'notafiscal-report',
		'cadastrolojistas-report',
		'vendedordistribuidor-report',
		'apuracao-report',
		'sellout-report',
		'historico-preco-estoque-report',
		'distribuidor-prioritario-report',
	]

	async function fetchMenu(force:boolean = false) {
		if (!force && menu && menu.length) return;
		const response = await getMenu();
		setMenu(response.data);
	}

	const getFeatures = async() => {
		const result = await getCompanyFeatures();
		setFeatures(result.data);
	}

	useEffect(() => { 
		fetchMenu();
		getFeatures();
	}, []);

	

	function renderMenu(item:any) {

		if (!item) return null;

		if (item.section) {
			return <>
			<div className='menu-item'>
				<div className='menu-content pt-8 pb-2 text-center'>
					<span className='menu-section text-muted text-uppercase fs-8 ls-1'>{ item.section }</span>
				</div>
			</div>
			{ item.children && item.children.map((x:any, i:number) => renderMenu(x) )}
			</>
		}

		const MenuElement = item.children && item.children.length > 0 ? AsideMenuItemWithSub : AsideMenuItem;

		if (item.label) {
			return <MenuElement
			key={item.id}
			to={item.to}
			title={item.label}
			fontIcon={item.fontIcon}
			icon={item.icon}
			hasBullet={item.hasBullet}
			>
				{ item.children && item.children.map((x:any, i:number) => renderMenu(x)) }
			</MenuElement>
		}
	}

	function onMenuChanged() {
		fetchMenu(true);
	}

	return <StyledMenu>
		<div className='pt-4'>
			<AsideMenuItem to='/home' icon='/media/icons/duotune/general/gen001.svg' title={'Página Inicial'} fontIcon='bi-app-indicator'/>
		</div>
		
		{ !!menu && menu.map((item, i) => <div key={'section' + i}>{renderMenu(item)}</div>) }
		<div key={'section_monitor'} className='mt-10'>
			{ features?.length ? 
				<div className='menu-item'>
				<div className='menu-content pt-8 pb-2 text-center'>
					<span className='menu-section text-muted text-uppercase fs-8 ls-1'>{ 'Ferramentas' }</span>
				</div>
				{ features?.some(feature => feature.name === 'alert-monitor') ? 
				<AsideMenuItemWithSub key={'alert_monitor'} to={''} title={'Monitor de Alertas'} hasBullet={false}>
					<AsideMenuItem key={'list_alerts'} to={'/alerts/list'} title={'Lista de alertas'} hasBullet={false}/>
					<AsideMenuItem key={'new_alert'} to={'/alerts/new'} title={'Gerar novo alerta'} hasBullet={false}/>
				</AsideMenuItemWithSub>
				: null }
				{ features?.some(feature => feature.name.includes('-report')) ? 
				<AsideMenuItemWithSub key={'all_reports'} to={''} title={'Relatórios'} hasBullet={false}>
					{ features?.some(feature => feature.name === 'pedidos-report') ? 
					<AsideMenuItem key={'reports_pedidos'} to={'/reports/pedidos'} title={'Pedidos'} hasBullet={false}/>
					: null }
					{ features?.some(feature => feature.name === 'promocoes-report') ? 
					<AsideMenuItem key={'reports_promos'} to={'/reports/promos'} title={'Promoções'} hasBullet={false}/>
					: null }
					{ features?.some(feature => feature.name === 'notafiscal-report') ? 
					<AsideMenuItem key={'reports_nota_fiscal'} to={'/reports/notafiscal'} title={'Nota Fiscal'} hasBullet={false}/>	
					: null }
					{ features?.some(feature => feature.name === 'cadastrolojistas-report') ? 
					<AsideMenuItem key={'reports_cadastro_lojistas'} to={'/reports/cadastro_lojistas'} title={'Cadastro Lojistas'} hasBullet={false}/>	
					: null }
					{ features?.some(feature => feature.name === 'vendedordistribuidor-report') ? 
					<AsideMenuItem key={'reports_cadastro_vendedores'} to={'/reports/cadastro_vendedores'} title={'Cadastro Vendedores'} hasBullet={false}/>	
					: null }
					{ features?.some(feature => feature.name === 'apuracao-report') ? 
					<AsideMenuItem key={'reports_apuracao'} to={'/reports/apuracao'} title={'Apuração'} hasBullet={false}/>	
					: null }
					{ features?.some(feature => feature.name === 'sellout-report') ? 
					<AsideMenuItem key={'reports_sellout'} to={'/reports/sellout'} title={'Sellout'} hasBullet={false}/>	
					: null }
					{ features?.some(feature => feature.name === 'historico-preco-estoque-report') ? 
					<AsideMenuItem key={'reports_historico_preco_estoque'} to={'/reports/historico_preco_estoque'} title={'Histórico Preço Estoque'} hasBullet={false}/>	
					: null }
					{ features?.some(feature => feature.name === 'distribuidor-prioritario-report') ? 
					<AsideMenuItem key={'reports_distribuidor_prioritario'} to={'/reports/distribuidor_prioritario'} title={'Distribuidor Prioritário'} hasBullet={false}/>	
					: null }
					{ features?.some(feature => feature.name === 'lojistas-report') ? 
					<AsideMenuItem key={'reports_lojistas'} to={'/reports/lojistas'} title={'Cashback Lojistas'} hasBullet={false}/>	
					: null }
					{ features?.some(feature => feature.name === 'cupons-report') ? 
					<AsideMenuItem key={'reports_cupons'} to={'/reports/cupons'} title={'Cashback Cupons'} hasBullet={false}/>	
					: null }
				</AsideMenuItemWithSub> : null }
				{ isSuperAdmin() ? <AsideMenuItem key={'logs'} to={'/logs'} title={'Logs'} hasBullet={false}/> : null }
			</div> : null }
		</div>
		<div className="space"></div>
		{ menu && !menu.length && <p className='mt-4'>Menu indisponível</p> }
	</StyledMenu>
}