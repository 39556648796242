import { PageTitle } from './../../../_metronic/layout/core'
import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as API from './../../api';
import {Modal} from 'react-bootstrap';
import UserModal from './UserModal';
import MessageModal from './../utils/MessageModal';
import DeleteUserModal from './DeleteUserModal';
import { isSuperAdmin } from '../../modules/auth';

import UserList from "./UserList";
import UsersPermissionMatrix from "./UsersPermissionMatrix";
import UserFeatureMatrix from "./UserFeatureMatrix";

const StyledUsers = styled.div`
    .card {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.15s ease-out;
    }

    .nav-item {
        cursor:pointer;
      }

    .card:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f3f3f3;
    }

    .btn-icon {
        transition: transform 0.15s ease-out;
        margin-right: 10px;
    }

    .btn-icon:hover {
        transform: scale(1.1);
    }

    .text-end {
        padding-right: 15px !important;
        
    }

    svg {
        width: 20px;
        opacity: 0.7;
    }

    .superadmin svg {
        width: 100%;
        color: red;
        opacity: 1;
    }
    
    .superadmin, .admin, .user {
        height: 40px;

        svg {
            width: 100%;
            height: 100%;
            opacity: 1;
        }
    }

    .superadmin svg {
        color: red;
    }

    .admin svg {
        color: orange;
    }

`;

const Users:FC = () => {
    const [activeTab, setActiveTab] = useState('users');
    const [selectedUser, setSelectedUser] = useState<any>();
    const [users, setUsers] = useState<any[]>([]);
    const [modal, showModal] = useState<boolean>(false);
    const [messageModal, showMessageModal] = useState<boolean>(false);
    const [deleteModal, showDeleteModal] = useState<boolean>(false);
    const [maxUsersReached, setMaxUsersReached] = useState<boolean>(false);
    const [superAdmins, setSuperAdmins] = useState<number>(0);

    function onDeleteUserClick(user:any) {
        setSelectedUser(user);
        showDeleteModal(true);
    }

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    function onEditUserClick(user:any) {
        setSelectedUser(user);
        showModal(true);
    }

    function onUserCreated() {
        updateData();
        showModal(false);
        setSelectedUser(null);
    }

    function onUserUpdated() {
        updateData();
        showModal(false);
        setSelectedUser(null);
    }

    function updateData() {
        API.listUsers().then((response) => {
            let superAdminCounter = 0;
            const users = response.data.users.filter((user:any) => {
                if (user.is_superadmin === 0) superAdminCounter++;
                return user.is_superadmin === 0;
            });
            setSuperAdmins(superAdminCounter);
            setUsers(isSuperAdmin() ? response.data.users : users);
            setMaxUsersReached(response.data.maxUsersReached);
        });
    }

    useEffect(() => {
        updateData(); 
    }, []);

    return <StyledUsers>
        <PageTitle>Administração de Usuário</PageTitle>
        <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={messageModal}>
            <MessageModal title='Função indisponível' description='Já foi cadastrado o limite de usuários máximo para essa empresa. Caso seja necessário mais usuários, entre em contato com nossa equipe.' onClose={() => {
                showMessageModal(false);
            }}/>
        </Modal>
        <Modal id='kt_modal_create_app' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={modal}>
            { <UserModal userData={selectedUser} onUserUpdated={() => { onUserUpdated() }} onUserCreated={() => { onUserCreated() }} onClose={() => {
                showModal(false);
                setSelectedUser(null);
            }} /> }
        </Modal>
        <Modal id='kt_modal_delete_app' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={deleteModal}>
            <DeleteUserModal userData={selectedUser} onClose={() => { 
                showDeleteModal(false);
                setSelectedUser(null);  }} 
                onUserDeleted={() => { 
                    showDeleteModal(false);
                    setSelectedUser(null); 
                    API.listUsers().then((response) => {
                        setUsers(response.data.users);
                        setMaxUsersReached(response.data.maxUsersReached);
                    });
            }}/>
        </Modal>
        <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
            <div className="card-toolbar">
                <ul className="nav nav-tabs nav-bold nav-tabs-line">
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'users' ? 'active' : ''}`} onClick={() => handleTabChange('users')}>
                    <span className="nav-icon">
                        <i className="flaticon2-chat-1"></i>
                    </span>
                    <span className="nav-text">Usuários</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'menu-permissions' ? 'active' : ''}`} onClick={() => handleTabChange('menu-permissions')}>
                    <span className="nav-icon">
                        <i className="flaticon2-drop"></i>
                    </span>
                    <span className="nav-text">Permissões</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'features' ? 'active' : ''}`} onClick={() => handleTabChange('features')}>
                    <span className="nav-icon">
                        <i className="flaticon2-drop"></i>
                    </span>
                    <span className="nav-text">Funcionalidades</span>
                    </a>
                </li>
                </ul>
            </div>
            </div>
            <div className="card-body">
            <div className="tab-content">
                <div className={`tab-pane fade show ${activeTab === 'users' ? 'active' : ''}`} id="users">
                    <UserList></UserList>
                </div>
                <div className={`tab-pane fade show ${activeTab === 'menu-permissions' ? 'active' : ''}`} id="menu-permissions">
                    {activeTab === 'menu-permissions' ? <UsersPermissionMatrix /> : <></>}
                </div>
                <div className={`tab-pane fade show ${activeTab === 'features' ? 'active' : ''}`} id="features">
                    {activeTab === 'features' ? <UserFeatureMatrix /> : <></>}
                </div>
            </div>
            </div>
        </div>
    </StyledUsers>

}

export default Users;