import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as API from '../../api';
import PermissionsModal from './PermissionsModal';
import {Modal} from 'react-bootstrap';
import UserShieldIcon from "../../components/UserShieldIcon";
import Select from 'react-select';

const StyledData = styled.div`
    .remove-user-title {
        margin:20px !important;
    }

    .table {
        margin:20px;
    }

    .card:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f6f6f6;
        
    }

    .accordion {
        width: 100%;
        margin: 20px auto;
    }

    .accordion-item {
        border-bottom: 1px dashed #ccc;
        margin-bottom: 10px;
    }

    .accordion-title {
        padding: 10px;
        cursor: pointer;
        background-color: #f9f9f9;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .accordion-title:hover {
        background-color: #ebebeb;
    }

    .accordion-title.active {
        background-color: #ebebeb;
    }

    .accordion-content {
        padding: 10px;
        display: none;
        background-color: #fff;
        border-radius: 4px;
    }

    .accordion-content.active {
        display: block;
    }

    .accordion > .table-responsive {
        margin-bottom: 20px;
    }

    .accordion > .table-responsive:last-child {
        margin-bottom: 0;
    }

    .first-col {
        padding-left: 15px !important;
        
    }

    .user-box {
        height: 40px;

        svg {
            width: 100%;
            height: 100%;
            opacity: 1;
        }

        img {
            width: 100%;
            height: 100%;
            opacity: 1;
        }
    }







    .table-container {
        overflow-y: auto;
        overflow-x: auto;
        max-height: 70vh;
        margin: 20px;
    }

    .table {
        width: 100%;
        border-collapse: collapse;
    }
    
    thead th {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;
    }
  
    .first-col,
    .first-col-header {
        position: sticky;
        left: 0;
        background-color: white;
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f6f6f6;
    }

    .filter-group {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        z-index: 1002;
    }

    .filter-radio {
        margin-right: 10px;
    }

    .filter-dropdown {
        position: relative;
    }

    .filter-dropdown-input {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 150px;
        margin-right: 5px;
    }

    .filter-dropdown-options {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        max-height: 200px;
        overflow-y: auto;
        display: none;
    }

    .filter-dropdown-option {
        padding: 5px 10px;
        cursor: pointer;
    }

    .filter-dropdown-option:hover {
        background-color: #f6f6f6;
    }

    .filter-dropdown:hover .filter-dropdown-options {
        display: block;
    }

    .filter-dropdown-selected {
        color: #333;
    }

    .form-group {
        z-index: 2000;
    }
`;

const FeatureMatrix:FC = () => {

    const [menu, setMenu] = useState<any>();
    const [users, setUsers] = useState<any[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [usersData, setUsersData] = useState<any>([]);

    const getFeatures = async() => {
        const result:any = await API.getUserFeatures();
        setMenu(result.data);
        if (result.success) {
            const data:any[] = [];
            result.data.users.map((item: any) => {
                const obj: { [key: string]: any; value: any; label: any } = {
                    value: item.id_user,
                    label: item.name
                };
                for (const key in item) {
                    if (item.hasOwnProperty(key)) {
                        obj[key] = item[key];
                    }
                }
                data.push(obj);
            });
            setUsers(data);
        }
    }

    async function featurePermissionClick(id_feature:number, guid:number, checked:boolean) {
        if (checked) {
            await API.addFeatureUserPermission({id_feature, guid});
        } else {
            await API.removeFeatureUserPermission({id_feature, guid});
        }
    }

    useEffect(() => {
        getFeatures();
    }, []);

    useEffect(() => {
        if (selectedUsers.length) {
            setUsersData(selectedUsers);
        } else {
            if (menu) setUsersData(menu.users);
        }
    }, [selectedUsers, menu?.users]);
    

    return <StyledData>
        <div key={`features`} className=''>
            <div className="filter-group w-100">
                <div className='form-group w-100'>
                    <label className='form-label fw-bold me-2 w-200px'>Buscar por Usuários:</label>
                    <Select
                        isMulti
                        options={users}
                        value={selectedUsers}
                        closeMenuOnSelect={false}
                        placeholder="Selecionar usuários"
                        onChange={(selected: any) => setSelectedUsers(selected)}
                        className="multi-select"
                    />
                </div>
            </div>
            { menu?.features?.length ? 
                    <div className="table-wrapper">
                    <div className="table-container">
                        <table className="table table-row-dashed table-row-gray-300 align-middle justify-content-center">
                            <thead>
                            <tr className="fw-bold text-muted">
                                <th className='w-200px first-col justify-content-center text-center'>
                                    <span className='btn'>Usuário</span>
                                </th>
                                    {menu?.features?.map((feature:any) => { return (
                                        <React.Fragment key={`c_p_${feature.id_feature}`}>
                                            <th key={`title_product_${feature.id_product}`} className='justify-content-center text-center'>
                                                <button className='btn btn-active-light-primary' onClick={() => {
                                                    
                                                }}>{feature.title}</button>
                                            </th>
                                            
                                        </React.Fragment>
                                    )})}
                                </tr>
                            </thead>
                            <tbody>
                                <>{ usersData.map((user:any) => { return (
                                    <tr key={`${user.guid}`} className='justify-content-left'>
                                        <td className='first-col w-200px'>
                                            <span className='d-flex user-box '>
                                                <div className='symbol symbol-45px me-5'>
                                                    { user.src ? 
                                                    <img src={user.src} alt='Foto de perfil' className='' /> :
                                                    <UserShieldIcon/>
                                                    }
                                                </div>
                                                
                                                <p className='text-muted fw-semibold text-muted d-block fs-7 first-col w-200px'>{user.name}</p>
                                            </span>
                                            
                                        </td>
                                        {menu.features.map((feature:any) => { return (
                                            <React.Fragment key={`cc_p_${feature.id_feature}_${user.guid}`}>
                                                <td key={Math.random()} className="justify-content-center align-middle">
                                                
                                                    <div className='form-check form-check-sm form-check-custom d-flex justify-content-center text-center'>
                                                        <input className='form-check-input widget-9-check ' type='checkbox' defaultChecked={menu.permissions.find((p:any) => { return p.id_feature == feature.id_feature && p.id_user == user.id_user })} onChange={(evt) => { featurePermissionClick(feature.id_feature, user.guid, evt.target.checked) }} />
                                                    </div>
                                                </td>
                                            </React.Fragment>
                                        )})}
                                    </tr>
                                )})}</>
                            </tbody>
                        </table>
                    </div></div>
                        
                : <div className='d-flex text-center justify-content-center'>
                    <p>Nenhuma funcionalidade encontrada para o seu plano atual</p>
                </div>}
            </div>
    </StyledData>

}

export default FeatureMatrix;