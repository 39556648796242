import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report } from 'powerbi-client';

import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import IPowerBI from './IPowerBI';

interface Props {
    edit:boolean,
    data:IPowerBI,
    home:boolean,
    action: any,
    setAction: any,
    selectedFilter: any
}

const StyledChart = styled.div<Props>`
    background-color: white;
    width: 100%;
    height: 100%;

    border: 1px solid #ddd;
    padding: 1rem;
    
    .report-style-class {
        width: 100%;
        height: 100%;
    }

    .content {
        opacity: ${props => props.edit && !props.home ? 0.5 : 1};
        pointer-events: ${props => props.edit ? 'none' : 'unset'};
        width: 100%;
        height: 100%;
    }

    &:hover {
        .content {
            opacity: 1;
        }
    }
`

const PowerBI:FC<Props> = (props:Props) => {

    const [report, setReport] = useState<Report | null>(null);
    const [page, setPage] = useState<any>(null);
    const [counter, setCounter] = useState<number>(0);
    const [filter, setFilter] = useState<any>(null);
    const [initialFilter, setInitialFilter] = useState<any>(null);

    function onClickChart() {
        
    }

    useEffect(() => {
        try {
            const obj = JSON.parse(props.selectedFilter);
            const json = JSON.parse(obj.data);
            page.setFilters(getOnlyValidFilters(json));
        } catch (e) {
            try {
                page.setFilters(filter);
            } catch (e2) { };            
        };
    }, [props.selectedFilter]);

    function getOnlyValidFilters(filters:any) {
        if (!initialFilter) return;
        const finalFilters:any = [];
        initialFilter.forEach((f:any) => {
            const filter = filters.find((item:any) => 
                item.target.table === f.target.table && 
                item.target.column === f.target.column &&
                item.filterType === f.filterType
            );
            finalFilters.push(filter ? filter : f);
        });
        return finalFilters;
    }

    async function checkFilters(page:any) {
        try {
            const filters:any = await page.getFilters();
            if (!initialFilter){
                setInitialFilter(filters);
            } 
            if (!filter) setFilter(filters);
            if (!props.action || JSON.stringify(filters) !== props.action.data) {
                const actionData = { type:'update-filter', data:JSON.stringify(filters) };
                props.setAction(actionData);
            } 
        } catch (e) { }
    }
    
    useEffect(() => {
        checkFilters(page);
    }, [counter]);

    useEffect(() => {
        if (report) {
            report.on('loaded', async() => {
                setInitialFilter(null);
                const page:any = await report.getActivePage();
                setPage(page);
                const intervalId = setInterval(() => { setCounter(Math.random()) }, 2000);
                const removeInterval = () => {
                    clearInterval(intervalId);
                }
                return removeInterval;
            });
        }
    }, [report]);

    console.log("POWERBI");

    return <StyledChart {...props}>
        <div className="content" onClick={onClickChart}>
            <PowerBIEmbed
                embedConfig = {{
                    type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                    id:props.data.report_id,
                    embedUrl:props.data.embedUrl,
                    accessToken:props.data.accessToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                visible: true
                            },
                            pageNavigation: {
                                visible: false
                            }
                        },
                        background: models.BackgroundType.Default,
                    }
                }}

                eventHandlers={
                    new Map([
                        ['loaded', function (event: any) { 
                            //console.log(event);
                            setReport(event.detail.report); 
                        }],
                        ['visualRendered', function (event: any) { 
                            setReport(event.detail.report); 
                        }],
                        ['rendered', function () { }],
                        ['error', function (event) { }]
                    ])
                }

                cssClassName={"report-style-class"}

                getEmbeddedComponent={(embeddedReport) => {
                    setReport(embeddedReport as Report); // Store the report instance
                }}
            />
        </div>
    </StyledChart>
}

export default PowerBI;

