import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled';
import * as API from '../../api';
import ReportFilterModal from './ReportFilterModal';
import BtnTrash from "../../components/BtnTrash";
import BtnEdit from "../../components/BtnEdit";

const Style = styled.div`
    .filters {
        gap: 5px;
    }
`;

interface Props {
    name: string;
    filter: any;
    setFilter: any;
}

const ReportFilter: FC<Props> = (props: Props) => {
    const [filters, setFilters] = useState<any[]>([]);
	const [selectedFilter, setSelectedFilter] = useState<any>('none'); // Initialize with 'none'
	const [currentFilter, setCurrentFilter] = useState<any>(null);
    const [filterModal, showFilterModal] = useState<boolean>(false);

    const [updateLoading, setUpdateLoading] = useState<boolean>(false);
    const [removeLoading, setRemoveLoading] = useState<boolean>(false);

    const getFilters = async() => {
        if (!props.name) return;
        const result = await API.getReportFilters(props.name);
        result.data.map((item:any) => {
            item.data = JSON.parse(item.data);
        });
        setFilters(result.data);
    }

    useEffect(() => {
        getFilters();
    }, []);

    useEffect(() => {
        if (!props.filter) return;
        setCurrentFilter(props.filter);
    }, [props])

    const updateFilter = async() => {
        try {
            setUpdateLoading(true);
            const currentFilter = getFilterById(selectedFilter);
            await API.updateReportFilter({id_report_filter:currentFilter.id_report_filter, filters:props.filter});
            getFilters();
            setUpdateLoading(false);
        } catch (e) { 
            setUpdateLoading(false);
        };
    }

    const removeFilter = async() => {
        try {
            setRemoveLoading(true);
            const currentFilter = getFilterById(selectedFilter);
            await API.removeReportFilter({id_report_filter:currentFilter.id_report_filter});
            getFilters();
            setRemoveLoading(false);
        } catch (e) { 
            setRemoveLoading(false);
         }
    }

    const getFilterById = (id:any) => {
        return filters.find((i) => i.id_report_filter == id);
    }

    return (
        <Style>
            <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-500px' className='backdrop-custom' show={filterModal}>
				<ReportFilterModal report={props.name} filters={currentFilter} onClose={(success:boolean) => {
					showFilterModal(false);
                    if (success) getFilters();
				}}/>
			</Modal>
            <div className="notice d-flex justify-content-between p-3 mb-3">
                <div className="form-check form-switch justify-content-center form-check-custom form-check-solid w-100">
                    { filters?.length === 0 ?
                    <div className='justify-content-center'>
                        <div className='justify-content-center'>
                            <label className="form-check-label p-4">Nenhum filtro salvo no momento</label>
                            <button type='submit' id='kt_sign_in_submit' className='btn btn-sm btn-success bg-success' onClick={() => { 
                                showFilterModal(true);
                            }}>Salvar filtro atual</button>
                        </div>
                    </div> :
                    <div className='filters form-check-custom w-100'>
                        <label className="form-check-label" htmlFor="flexSwitchDefault">Filtros:</label>
                        <select
                            className="form-select form-select-lg form-select-solid form-item"
                            value={selectedFilter} // This should be controlled via component state
                            onChange={({ target: { value } }) => {
                                setSelectedFilter(value);
                                console.log("VALUE", value);
                                props.setFilter(getFilterById(value));
                            }}
                        >
                            <option key={'none'} value={'none'}>Nenhum filtro salvo selecionado</option>
                            {
                                filters.map((filter, index) => 
                                    <option key={'option' + index} value={filter.id_report_filter}>{filter.name}</option>
                                )
                            }
                        </select>
                        { props.filter && props.filter !== 'none' ? <>
                            <button type='submit' id='kt_sign_in_submit' className='btn btn-sm btn-primary bg-primary' onClick={() => { 
                                updateFilter();
                            }}>
                            {!updateLoading && <span className='indicator-label' ><BtnEdit/></span>}
                            {updateLoading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            </button>
                            <button type='submit' id='kt_sign_in_submit' className='btn btn-sm btn-danger bg-danger' onClick={() => { 
                                removeFilter();
                            }}>
                            {!removeLoading && <span className='indicator-label' ><BtnTrash/></span>}
                            {removeLoading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            </button>
                        </>: <></>
                        }
                        <button type='submit' id='kt_sign_in_submit' className='btn btn-lg btn-success bg-success' onClick={() => { 
                            showFilterModal(true);
                        }}>Criar</button>
                    </div>}
                </div>
                <div className="space"></div>
            </div>
        </Style>
    )
}

export default ReportFilter