import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import BtnClose from './../../components/BtnClose';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import * as API from '../../api';

const StyledModal = styled.div`
    background-color: white;

    .card-header {
        align-items: center;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    img {
        width: 50%;
        margin: auto;
    }
`;


interface Props {
    title?:string,
    description?: string
    onClose: Function
}

const MFAActiveModal:FC<Props> = (props:Props) => {
    const [isLoading, setIsLoading] = useState<any>(false);
    const [isInvalid, setIsInvalid] = useState<any>(false);
    const [image, setImage] = useState<any>(null);
    const [code, setCode] = useState<any>('');
    const [secret, setSecret] = useState<any>('');

    useEffect(() => {
        getMFACode();
    }, []);

    const getMFACode = async() => {
        const response = await API.generateMFA();
        setImage(response.qrCodeUrl);
        setSecret(response.secret);
        console.log(response);
    }
    const validate = async() => {
        setIsLoading(true);
        const response = await API.addAndValidateMFA({code, secret});
        if (response.success) {
            props.onClose(true);
        } else {
            setIsLoading(false);
            setIsInvalid(true);

        }
    }

    return (
        <StyledModal className="card mb-5 mb-xl-10">
            <div className='card-header border-0'>
            <h3>{`Cadastro de MFA`}</h3>    
                
            </div>
            <div className='card mb-5 mb-xl-10 text-center'>
                <div className='card-title m-0'>
                    
                </div>
                <div className='card-body pt-9 pb-0'>
                <h3>{`Sua empresa exige o uso de dois fatores de segurança.`}</h3>
                <p>Baixe o aplicativo <b>Google Authenticator</b> para Android ou iOS</p>
                <div className='d-flex  justify-content-center'>
                    <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share'>
                        <img className='w-30px h-30px me-5 btn-' src={toAbsoluteUrl('/media/svg/utils/android.svg')} alt=''/>
                    </a>
                    <a href='https://apps.apple.com/br/app/google-authenticator/id388497605'>
                        <img className='w-30px h-30px me-5' src={toAbsoluteUrl('/media/svg/utils/ios.svg')} alt=''/>
                    </a>
                </div>
                    <img src={image}/>
                    <p>Após a instalação, leia o QR Code e confirme os digitos para validação.</p>
                    <div className='form-group justify-content-center text-center'>
                        <label className='form-label me-2'>Código de 6 dígitos gerado pelo <b>Authenticator</b>:</label>
                        <div className='d-flex text-center justify-content-center'>
                        <input className="form-input form-control w-150px text-center" maxLength={6} type='text' value={code} onChange={(e) => {
                            setCode(e.target.value);
                        }}></input>
                        </div>
                        
                    </div>
                    { isInvalid ? <label className='form-label me-2 text-danger'>Código inválido, tente novamente</label> : null }
                    <div className=' justify-content-center text-center'>
                        <button onClick={() => {
                            validate();                        
                        }} className='btn btn-primary filter-btn mt-5 w-50'>{!isLoading && 'Validar'} {isLoading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                Aguarde...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            )}
                        </button>
                    </div>
                    
                </div>
            </div>
        </StyledModal>
    )
}

export default MFAActiveModal