import { FC, useState, useEffect } from 'react';
import BtnClose from '../../components/BtnClose';
import DatePicker from 'react-datepicker';
import styled from '@emotion/styled';
import * as API from '../../api';
import Select from 'react-select';
import ReportFilter from './ReportFilter';
import { useDistributors, useRegions, useIndustries } from '../../../app/modules/auth';

const Style = styled.div`

    
  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }

  .radio-item {
    margin-right: 30px;
    
    label {
        margin-right: 5px;
    }

    .opacity-test {
    
        background-color: red;
    }
`;

type Props = {
    onChange: Function,
    onClose: Function,
    data: any,
    type: string
}

const FilterModal: FC<Props> = (props) => {

    const distributors = useDistributors();
    const industries = useIndustries();
    const regions = useRegions();

    const [loading, setLoading] = useState(false);

    const [selectedDistributors, setSelectedDistributors] = useState<any[]>([]);
    const [selectedIndustries, setSelectedIndustries] = useState<any[]>([]);
    const [selectedRegions, setSelectedRegions] = useState<any[]>([]);

    const [status, setStatus] = useState<string>(props.type == 'preco_estoque_hist' ? 'DataPreco' : 'captado');
    const [cnpj, setCNPJ] = useState<string>('');
    const [regraPromocao, setRegraPromocao] = useState<string>('');
    const [idPedido, setIdPedido] = useState<string>('');
    const [idSKU, setIdSKU] = useState<string>('');
    const [EAN, setEAN] = useState<string>('');
    const [produto, setProduto] = useState<string>('');
    const [marca, setMarca] = useState<string>('');
    const [politicaPreco, setPoliticaPreco] = useState<string>('');
    const [dataFaturadoIntegracao, setDataFaturadoIntegracao] = useState<string>('');
    const [dataNFIntegracao, setDataNFIntegracao] = useState<string>('');
    const [groupBySKU, setGroupBySKU] = useState<string>('');

    const [filter, setFilter] = useState<any>(null);
    
    const [startDate, setStartDate] = useState<Date>(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const minDate = new Date(new Date((new Date()).setMonth((new Date()).getMonth() - 18)));
    const maxDate = new Date();

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        clear();
        if (filter || props.data) {
            const data = filter?.data || props.data;
            if (data.status) setStatus(data.status);
            if (data.distributors) setSelectedDistributors(data.distributors);
            if (data.industries) setSelectedIndustries(data.industries);
            if (data.dtstart) setStartDate(new Date(new Date(data.dtstart).setDate(new Date(data.dtstart).getDate() + 1)));
            if (data.dtend) setEndDate(new Date(new Date(data.dtend).setDate(new Date(data.dtend).getDate() + 1)));
            if (data.cnpj) setCNPJ(data.cnpj);
            if (data.idPedido) setIdPedido(data.idPedido);
            if (data.idSKU) setIdSKU(data.idSKU);
            if (data.produto) setProduto(data.produto);
            if (data.politicaPreco) setPoliticaPreco(data.politicaPreco);
            if (data.ean) setEAN(data.ean);
            if (data.regraPromocao) setRegraPromocao(data.regraPromocao);
            if (data.regions) setSelectedRegions(data.regions);
            if (data.marca) setMarca(data.marca);
            if (data.dataNFIntegracao) setDataNFIntegracao(data.dataNFIntegracao);
            if (data.dataFaturadoIntegracao) setDataFaturadoIntegracao(data.dataFaturadoIntegracao);
            if (data.groupBySKU) setGroupBySKU(data.groupBySKU);
        }
    }, [props.data, filter]);

    function clear() {
        setStatus(props.type == 'preco_estoque_hist' ? 'DataPreco' : 'captado');
        setSelectedDistributors([]);
        setSelectedIndustries([]);
        setStartDate(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)));
        setEndDate(new Date());
        setCNPJ('');
        setIdPedido('');
        setIdSKU('');
        setProduto('');
        setPoliticaPreco('');
        setEAN('');
        setRegraPromocao('');
        setSelectedRegions([]);
        setMarca('');
        setDataNFIntegracao('');
        setDataFaturadoIntegracao('');
        setGroupBySKU('');
    }

    function getData() {
        return {
            status,
            distributors:selectedDistributors,
            industries:selectedIndustries,
            dtstart:getStringDate(startDate),
            dtend:getStringDate(endDate),
            cnpj,
            idPedido,
            idSKU,
            produto,
            politicaPreco,
            ean:EAN,
            regraPromocao,
            regions:selectedRegions,
            marca,
            dataNFIntegracao,
            dataFaturadoIntegracao,
            groupBySKU
        }
    }

    function getStringDate(date:Date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
    
        const dayFormatted = day < 10 ? '0' + day : day.toString();
        const monthFormatted = month < 10 ? '0' + month : month.toString();
    
        return `${year}-${monthFormatted}-${dayFormatted}`;
    }

    return (
        <Style>
            <div className='modal-container opacity-test'>
                
                <div className='px-7 py-5 d-flex justify-content-between'>
                    <div className='pt-2 fs-3 text-dark fw-bolder'>Filtros para o relatório</div>
                    <BtnClose onClick={() => props.onClose()} />    
                </div>
                <ReportFilter name={props.type} filter={getData()} setFilter={setFilter}></ReportFilter>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5'>
                    <div className='mb-4 d-flex flex-wrap'>
                        <div className=' justify-content-between modal-container'>
                            { props.type != 'preco_estoque_hist' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Status:</label>
                                <select  disabled={loading} className='form-select form-select-solid' data-kt-select2='true' data-placeholder='' data-allow-clear='true' value={status} onChange={(evt:any) => setStatus(evt.target.value)}>
                                    <option value='captado'>Captado</option>
                                    <option value='aprovado'>Aprovado</option>
                                    <option value='faturado'>Faturado</option>
                                    <option value='entregue'>Entregue</option>
                                    <option value='cancelado'>Cancelado</option>
                                </select>
                            </div> : 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Status:</label>
                                <select  disabled={loading} className='form-select form-select-solid' data-kt-select2='true' data-placeholder='' data-allow-clear='true' value={status} onChange={(evt:any) => setStatus(evt.target.value)}>
                                    <option value='DataPreco'>Data Preço</option>
                                    <option value='DataEstoque'>Data Estoque</option>
                                </select>
                            </div> }
                            
                            
                            <div className='form-group'>
                                <label className='form-label fw-bold w-150px'>Inicio:</label>
                                <DatePicker  disabled={loading} dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={(date:any) => { setStartDate(date) }} className="form-input form-control" minDate={minDate} maxDate={maxDate}/>
                            </div>

                            <div className='form-group'>
                                <label className='form-label fw-bold w-150px'>Término:</label>
                                <DatePicker  disabled={loading} dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={(date:any) => { setEndDate(date) }} className="form-input form-control" minDate={minDate} maxDate={maxDate}/>
                            </div>

                            { props.type == 'pedidos' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Agrupado por Pedido:</label>
                                <div className='form-control d-flex'>
                                    <div className='radio-item'>
                                        <label>Sim</label>
                                        <input className='form-check-input' type='radio' name='groupBySKU' value='SIM' checked={ groupBySKU === 'SIM' } onChange={({ target: { value } }) => { setGroupBySKU(value) }}/>
                                    </div>                                
                                    <div className='radio-item'>
                                        <label>Não</label>
                                        <input className='form-check-input' type='radio' name='groupBySKU' value='' checked={ groupBySKU === '' } onChange={({ target: { value } }) => { setGroupBySKU(value) }}/>
                                    </div>
                                </div>                                
                            </div> : null }

                            { props.type != 'preco_estoque_hist' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>CNPJ:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={cnpj} onChange={(e) => {
                                    setCNPJ(e.target.value);
                                }}></input>
                            </div> : null }

                            
                            { props.type == 'promocoes' ?
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Nome Promoção:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={regraPromocao} onChange={(e) => {
                                    setRegraPromocao(e.target.value);
                                }}></input>
                            </div> : null }

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Distribuidor:</label>
                                <Select
                                    isMulti
                                    isDisabled={loading}
                                    options={distributors}
                                    value={selectedDistributors}
                                    placeholder={'Filtrar por Distribuidor'}
                                    closeMenuOnSelect={false}
                                    onChange={(selected: any) => setSelectedDistributors(selected)}
                                    className="multi-select"
                                />
                            </div>
                            
                            { groupBySKU == '' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Indústria:</label>
                                <Select
                                    isMulti
                                    isDisabled={loading}
                                    options={industries}
                                    value={selectedIndustries}
                                    placeholder={'Filtrar por Indústria'}
                                    closeMenuOnSelect={false}
                                    onChange={(selected: any) => setSelectedIndustries(selected)}
                                    className="multi-select"
                                />
                            </div> : null }

                            { props.type != 'preco_estoque_hist' && groupBySKU == '' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Regional:</label>
                                <Select
                                    isMulti
                                    isDisabled={loading}
                                    options={regions}
                                    value={selectedRegions}
                                    placeholder={'Filtrar por Regional'}
                                    closeMenuOnSelect={false}
                                    onChange={(selected: any) => setSelectedRegions(selected)}
                                    className="multi-select"
                                />
                            </div> : null }
                            
                            { props.type == 'preco_estoque_hist' ?
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Politica Preço:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={politicaPreco} onChange={(e) => {
                                    setPoliticaPreco(e.target.value);
                                }}></input>
                            </div> : null }
                            
                            { props.type != 'preco_estoque_hist' ?
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>ID do Pedido:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={idPedido} onChange={(e) => {
                                    setIdPedido(e.target.value);
                                }}></input>
                            </div> : null }

                            { props.type == 'preco_estoque_hist' ?
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>EAN:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={EAN} onChange={(e) => {
                                    setEAN(e.target.value);
                                }}></input>
                            </div> : null }
                            
                            { props.type != 'notafiscal' && props.type != 'preco_estoque_hist' && groupBySKU == '' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>ID SKU:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={idSKU} onChange={(e) => {
                                    setIdSKU(e.target.value);
                                }}></input>
                            </div> : null }
                            
                            { props.type != 'notafiscal' && groupBySKU == '' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Nome do Produto:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={produto} onChange={(e) => {
                                    setProduto(e.target.value);
                                }}></input>
                            </div> : null }

                            { props.type == 'preco_estoque_hist' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Marca:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={marca} onChange={(e) => {
                                    setMarca(e.target.value);
                                }}></input>
                            </div> : null }
                            
                            { props.type == 'notafiscal' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Data Nota Fiscal Integração:</label>
                                <div className='form-control d-flex'>
                                    <div className='radio-item'>
                                        <label>Todos</label>
                                        <input className='form-check-input' type='radio' name='nfIntegracao' value='' checked={ dataNFIntegracao === '' } onChange={({ target: { value } }) => { setDataNFIntegracao(value) }}/>
                                    </div> 
                                    <div className='radio-item'>
                                        <label>Sim</label>
                                        <input className='form-check-input' type='radio' name='nfIntegracao' value='SIM' checked={ dataNFIntegracao === 'SIM' } onChange={({ target: { value } }) => { setDataNFIntegracao(value) }}/>
                                    </div>                                
                                    <div className='radio-item'>
                                        <label>Não</label>
                                        <input className='form-check-input' type='radio' name='nfIntegracao' value='NAO' checked={ dataNFIntegracao === 'NAO' } onChange={({ target: { value } }) => { setDataNFIntegracao(value) }}/>
                                    </div>
                                </div>                                
                            </div> : null }

                            { props.type == 'notafiscal' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Data Faturado Integração:</label>
                                <div className='form-control d-flex'>
                                    <div className='radio-item'>
                                        <label>Todos</label>
                                        <input className='form-check-input' type='radio' name='dataFaturadoIntegracao' value='' checked={ dataFaturadoIntegracao === '' } onChange={({ target: { value } }) => { setDataFaturadoIntegracao(value) }}/>
                                    </div> 
                                    <div className='radio-item'>
                                        <label>Sim</label>
                                        <input className='form-check-input' type='radio' name='dataFaturadoIntegracao' value='SIM' checked={ dataFaturadoIntegracao === 'SIM' } onChange={({ target: { value } }) => { setDataFaturadoIntegracao(value) }}/>
                                    </div>                                
                                    <div className='radio-item'>
                                        <label>Não</label>
                                        <input className='form-check-input' type='radio' name='dataFaturadoIntegracao' value='NAO' checked={ dataFaturadoIntegracao === 'NAO' } onChange={({ target: { value } }) => { setDataFaturadoIntegracao(value) }}/>
                                    </div>
                                </div>                                
                            </div> : null }
                            
                            <button onClick={() => {
                                setLoading(true);
                                props.onChange(getData());
                            }} className='btn btn-primary filter-btn'>{!loading && 'Filtrar'} {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Aguarde...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
}

export default FilterModal;