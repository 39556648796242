import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { messages } from '../utils/ErrorsText';
import { isSuperAdmin } from '../../modules/auth/core/Auth';
import { getUserData } from '../../modules/auth/core/Auth';
import IconAttachment from './../../components/IconAttachment';
import BtnView from './../../components/BtnStatusOpen';

const StyledModal = styled.div`
    .card-header {
        align-items: center;
        background-color: #f8f9fa;
        border-bottom: 1px solid #dee2e6;
    }

    .card-title {
        color: #495057;
    }

    .nps {
        margin-top: 40px;
    }

.nps-loading {
        margin-top: 60px;
        margin-bottom: 40px;
        font-size: 14px;
    }

    .nps-box {
        display: flex;
        flex-direction: column; // Altere para 'column'
        align-items: center;
        margin: 10px;

        .nps-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .nps-images {
            display: flex;
            flex-direction: row;
            justify-content: center;

            img {
                max-width: 50px; // Adicione esta linha
                margin: 2px;
                cursor: pointer;
                transition: opacity 0.3s ease;
            }
        }
    }

    button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0056b3;
        }
    }
`;

interface Props {
    userData?: any,
    onClose: Function
}

const NPSModal:FC<Props> = (props:Props) => {
    const [loading, setLoading] = useState(false);
    const [nps, setNPS] = useState<any>([]);
    const [npsIsLoaded, setNPSIsLoaded] = useState<boolean>(false);
    const [ratings, setRatings] = useState<{ [key: string]: number }>({});

    const ratingNames = ['Insatisfeito', 'Triste', 'Neutro', 'Bom', 'Ótimo']

    function getData() {
        const data = [];
        for (const key in ratings) {
            const item = getNPSById(key);
            data.push({id_nps:key, title:item.title, value:ratings[key]});
        }
        return data;
    }

    function getNPSById(id:string) {
        for (let a = 0; a < nps.length; a++) {
            if (nps[a].id_nps == id) return nps[a];
        }
        return null;
    }

    async function onSubmit() {
        const data = getData();
        setLoading(true);        
        try {
            await API.saveNPS(data);
            setLoading(false)
            props.onClose();
        } catch (e) {
            console.log(e);
            setLoading(false)
        }
    }

    function handleRatingChange(rating: any, group: string) {
        setRatings(prevRatings => ({ ...prevRatings, [group]: rating }));
    }

    const getNPS = async() => {
        const response = await API.getNPS();
        if (response.data) setNPS(response.data);
        setNPSIsLoaded(true);
    }

    useEffect(() => {
        getNPS();
    }, []);

    const allRatingsSelected = nps.length === Object.keys(ratings).length;

    return (
        <StyledModal className="card mb-5">
            <div className='card-header border-0'>
                <div className='card-title m-0'>
                    
                </div>
                <BtnClose onClick={() => props.onClose()} />
            </div>
            
            <div className='card mb-5 mb-xl-10'>        
                <div className='card-body text-center pb-0'>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sua opinião é muito importante para nós, ajude-nos a avaliar o Insight Experience</p>
                    { npsIsLoaded ? <><div className='nps'>
                    { nps.map((item:any) => (
                        <div key={item.id_nps} className='nps-box'>
                            <div className='nps-title'>{item.title}</div>
                            { item.type === 'emoticon' ? 
                            <div className='nps-images'>
                                {[1, 2, 3, 4, 5].map(rating => (
                                    <img 
                                        src={toAbsoluteUrl(`/media/rating/rating_${rating}.png`)}
                                        alt={ratingNames[rating - 1]}
                                        key={'img_' + Math.random()}
                                        title={ratingNames[rating - 1]}
                                        style={{ opacity: ratings[item.id_nps] === rating ? 1 : 0.4 }} 
                                        onClick={() => handleRatingChange(rating, item.id_nps)}
                                    />
                                ))}
                            </div>
                            : item.type === 'number' ?
                            <div key={item.id_nps} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                {[1, 2, 3, 4, 5].map(number => (
                                    <button 
                                        key={'button_' + Math.random()}
                                        style={{ 
                                            opacity: ratings[item.id_nps] === number ? 1 : 0.4,
                                            marginRight: '10px'
                                        }} 
                                        onClick={() => handleRatingChange(number, item.id_nps)}
                                    >
                                        {number}
                                    </button>
                                ))}
                            </div>
                            : item.type === 'text' ?
                            <div key={item.id_nps} style={{ marginTop: '10px' }}>
                            <textarea 
                                style={{ 
                                    width: '700px', 
                                    height: '100px', 
                                    padding: '10px', 
                                    border: '1px solid #ccc', 
                                    borderRadius: '4px',
                                    resize: 'none'
                                }} 
                                onChange={(e) => handleRatingChange(e.target.value, item.id_nps)} 
                            />
                        </div>
                            : <></> }
                        </div>
                    ))}
                    </div>
                    <button type='submit' id='kt_sign_in_submit' className='btn btn-sm btn-primary w-25 mt-5' onClick={() => { onSubmit() }} disabled={!allRatingsSelected || loading}>
                    {!loading && <span className='indicator-label' >Enviar</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Aguarde..
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                    
                </button>
                </>: <div className='nps-loading'>Carregando</div> }
            
            </div>
        </div>
    </StyledModal>
)
}

export default NPSModal;