import { FC, useState, useEffect } from 'react';
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import styled from '@emotion/styled';
import WorksheetIcon from './../../components/WorksheetIcon';
import PreloaderIcon from './../../components/PreloaderIcon';

const Style = styled.div`

  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }
`;

type Props = {
    onClose: Function,
	  url?: String,
    error?: Boolean,
    counter: number,
    jobId?: String
}

const DownloadModal: FC<Props> = (props) => {
    const [loading, setLoading] = useState(true);
    const [url, setURL] = useState(null);
    const [error, setError] = useState(false);
    const limit = 2000000;

    async function checkJobRoutine() {
      try {
        setTimeout(async () => {
          try {
            const request = await API.checkJobId(props.jobId);
            if (!request) {
              setError(true);
              return;
            }
            const response = request.data;
            if (response.data.status == 'progress') {
              checkJobRoutine();
            } else {
              if (response.data.status == 'finish') {
                const json = JSON.parse(response.data.data);
                setLoading(false);
                if (json.error) {
                  setError(true);
                } else {
                  setURL(json.url);
                }
              }
            }
          } catch (error) {
            console.error('Request failed', error);
            setError(true);
          }
        }, 5000);
      } catch (error) {
        console.error('Error in setTimeout', error);
        setError(true);
      }
    }

    useEffect(() => {
      setError(false);
      setURL(null);
    }, []);

    useEffect(() => {
      if (props.url != '') setLoading(false);
    }, [props.url]);

    useEffect(() => {
      if (!props.jobId) return;
      checkJobRoutine();
    }, [props.jobId]);


    return (
        <Style>
            <div className='modal-container text-center justify-content-center'>
                
                <div className='px-7 py-5 d-flex text-center justify-content-between'>
                    <div className='pt-2 fs-3 text-dark fw-bolder'>{props.counter > limit ? 'Tivemos um problema' : 'Gerando relatório'}</div>
					<div>
						{ loading && (!props.error && !error) && props.counter < limit ? null : <BtnClose onClick={() => props.onClose()} /> }
					</div>
                    
                </div>
                
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5  text-center justify-content-center d-flex mt-10 mb-10'>
                    { props.counter > limit ? 
                    <div>
                      <p>Limite de 2 milhões de registros excedido.</p>
                      <p>Realize um filtro mais específico para exportar uma planilha</p>
                    </div> : 
                    loading && (!props.error && !error) ? 'Aguarde, essa solicitação pode levar alguns minutos' : props.error || error ? 'Erro ao gerar relatório' : 'Relatório gerado com sucesso!' }
                </div>
				{ loading && (!props.error && !error) && props.counter < limit ? <div className=''>
					<PreloaderIcon/>
				</div> : null }
				<div className='mb-10 text-center justify-content-center d-flex'>
					{ loading || props.error || error ? null : <a className='d-flex' href={`${process.env.REACT_APP_API_URL}${props.url || url}`}>Clique aqui para baixar<WorksheetIcon/></a> }
          { props.error || error ? <p>Tente novamente mais tarde</p> : null }
				</div>
				
            </div>
        </Style>
    )
}

export default DownloadModal;